/* eslint-disable */

/**
 * 该文件为脚本自动生成文件，请勿随意修改。如需修改请联系 PMC
 * */

export default {
  /** 多个输入框之间是否需要间隔 */
  separate: Boolean,
};
