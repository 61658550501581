import { computed, PropType, defineComponent } from 'vue';
import renderFn from '../utils/render-fn';
import {
  TdIconSVGProps, SVGJson,
} from '../utils/types';
import useSizeProps from '../utils/use-size-props';

import '../style/css';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 .63l2.9 8.35 8.84.18-7.04 5.34 2.56 8.46L12 17.91l-7.26 5.05L7.3 14.5.26 9.16l8.84-.18L12 .63z"}}]};

export default defineComponent({
  name: 'StarFilledIcon',
  props: {
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  setup(props, { attrs }) {
    const propsSize = computed(() => props.size);

    const { className, style } = useSizeProps(propsSize);

    const finalCls = computed(() => ['t-icon', 't-icon-star-filled', className.value]);
    const finalStyle = computed(() => ({ ...style.value, ...(attrs.style as Styles) }));
    const finalProps = computed(() => ({
      class: finalCls.value,
      style: finalStyle.value,
      onClick: (e:MouseEvent) => props.onClick?.({ e }),
    }));
    return () => renderFn(element, finalProps.value);
  },

});
