import { computed, PropType, defineComponent } from 'vue';
import renderFn from '../utils/render-fn';
import {
  TdIconSVGProps, SVGJson,
} from '../utils/types';
import useSizeProps from '../utils/use-size-props';

import '../style/css';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 23a11 11 0 100-22 11 11 0 000 22zm-.17-11.11c.43-.53.97-.97 1.4-1.32A2 2 0 0012 7a2 2 0 00-1.89 1.33l-.33.95L7.9 8.6l.34-.94a4 4 0 116.24 4.47 7 7 0 00-1.1 1.01c-.27.34-.37.61-.37.85v1.25h-2V14c0-.87.39-1.57.83-2.11zM11 18.25v-2h2v2h-2z"}}]};

export default defineComponent({
  name: 'HelpCircleFilledIcon',
  props: {
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  setup(props, { attrs }) {
    const propsSize = computed(() => props.size);

    const { className, style } = useSizeProps(propsSize);

    const finalCls = computed(() => ['t-icon', 't-icon-help-circle-filled', className.value]);
    const finalStyle = computed(() => ({ ...style.value, ...(attrs.style as Styles) }));
    const finalProps = computed(() => ({
      class: finalCls.value,
      style: finalStyle.value,
      onClick: (e:MouseEvent) => props.onClick?.({ e }),
    }));
    return () => renderFn(element, finalProps.value);
  },

});
