import { computed, PropType, defineComponent } from 'vue';
import renderFn from '../utils/render-fn';
import {
  TdIconSVGProps, SVGJson,
} from '../utils/types';
import useSizeProps from '../utils/use-size-props';

import '../style/css';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 1h12.41L21 6.59V11h-2V9h-6V3H5v18h16v2H3V1zm12 2.41V7h3.59L15 3.41zM6 12h3.71c.71 0 1.29.58 1.29 1.29v2.42c0 .71-.58 1.29-1.29 1.29H8v3H6v-8zm2 3h1v-1H8v1zm3.5-3h3.71c.71 0 1.29.58 1.29 1.29v5.42c0 .71-.58 1.29-1.29 1.29H11.5v-8zm2 2v4h1v-4h-1zm3.5-.71c0-.71.58-1.29 1.29-1.29h3.38v2H19v1h2.67v2H19v3h-2v-6.71z"}}]};

export default defineComponent({
  name: 'FilePdfIcon',
  props: {
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  setup(props, { attrs }) {
    const propsSize = computed(() => props.size);

    const { className, style } = useSizeProps(propsSize);

    const finalCls = computed(() => ['t-icon', 't-icon-file-pdf', className.value]);
    const finalStyle = computed(() => ({ ...style.value, ...(attrs.style as Styles) }));
    const finalProps = computed(() => ({
      class: finalCls.value,
      style: finalStyle.value,
      onClick: (e:MouseEvent) => props.onClick?.({ e }),
    }));
    return () => renderFn(element, finalProps.value);
  },

});
